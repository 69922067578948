.applicationeditformcontentNameCategory {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.applicationeditformcontentVisibilityRoleRunMode {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.applicationeditformcontentLaunchstep {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}
