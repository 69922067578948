.backgroundStyle {
  height: auto;
  background: var(--one-color-cobas-white);
  border-radius: var(--one-border-radius-4);
  box-sizing: border-box;
}
.backgroundStyleAll {
  height: auto;
  background: var(--one-color-cobas-white);
  border-radius: var(--one-border-radius-4);
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.fullscreen_more_vertical {
  margin-right: 26px;
}
.all_application_menu {
  margin-right: 36px;
}

.applicationGroupStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0 15px 18px;
  & > .backstyle {
    margin: 0 -15px;
    border-bottom: 1px solid #d3d3d3;
  }
}

.nameIconStyle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.backgroundStyle {
  height: auto;
  background: var(--one-color-cobas-white);
  border-radius: var(--one-border-radius-4);
  box-sizing: border-box;
}
.allApplicationSearch {
  width: 282px;
  height: 48px;
  justify-content: center;
  align-items: center;
}
