.equipmentpageFilterButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.equipmentpageFilterSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.equipmentpageButton {
  display: flex;
  justify-content: space-between;
}
.equipmentpageFooter {
  display: flex;
  margin-left: auto;
}
.equipmentpageButtons {
  display: flex;
  color: var(--one-color-interaction-default-neutral-3);
  margin-right: 20px;
  cursor: pointer;
}
.equipmentpageIcon {
  margin-right: 7px;
}
.equipmentActivepageIcon {
  margin-right: 7px;
  color: var(--one-color-interaction-active-brand-1);
}
.equipmentpageText {
  color: var(--one-color-interaction-default-neutral-3);
}
.equipmentActivepageText {
  color: var(--one-color-interaction-active-brand-1);
}
.equipmentFilterIcon {
  height: 36px;
}
.active {
  color: var(--one-color-interaction-active-brand-1);
}
