.severity {
  text-transform: capitalize;
  &.high {
    color: var(--one-color-accent-tertiary-4);
  }
  &.medium {
    color: var(--one-color-accent-secondary-3);
  }
  &.low {
    color: var(--one-color-accent-octal-3);
  }
}
.filterSelected {
  color: var(--one-color-background-brand-1);
}
.filterNotSelected {
  color: var(--one-color-background-brand-1);
}
