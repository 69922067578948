.backgroundStyleDashboard {
  height: auto;
  background: var(--one-color-cobas-white);
  border-radius: var(--one-border-radius-4);
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.TopBarApplication {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
