.Title {
  color: var(--one-color-gray-800);
  margin-bottom: 20px;
}

.WidgetMainBox {
  width: 100%;
}
.HeaderBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
