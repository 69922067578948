.mainBoxForm {
  width: 100%;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
}

.actionFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.MainPageBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--one-color-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  height: 539px;
}

.formControlType {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 4px;
  border-bottom: "1px solid var(--one-color-gray-600)";
  text-decoration: "none";
}

.headerTitle {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}

.slideToggleTitle {
  margin-top: 10px;
  margin-right: 10px;
}

.SlideToggle {
  margin-right: 15px;
}
.selectBannerColor {
  vertical-align: middle;
}

.titleslidetoggle {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
}
