@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.RocheTopBarInner-rightIcons-3 {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: 10px;
}

@media only screen and (max-width: 960px) {
  .RocheTopBarInner-rightIcons-3 {
    justify-content: center;
    margin-right: 10px;
  }
}
*::-webkit-scrollbar {
  width: 12px;
  background: var(--one-color-gray-50);
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--one-color-gray-400);
  border-radius: 6px;
  border: 3px solid var(--one-color-gray-50);
}

.container {
  margin-top: 100px;
}
.heightwidth {
  max-height: 300px;
  overflow: auto;
  max-width: 70%;
  margin: auto;
  margin-bottom: 20px;
}
.setting-icon {
  justify-content: right;
  padding: 0%;
}
.admin-heading {
  justify-content: left;
  width: 100%;
}
.down-arrow {
  justify-content: left;
  width: 100%;
}
.settingdropDown {
  display: flex;
  flex-direction: column;
}
/* used for all field circle_clear icon */
.pointer {
  cursor: pointer;
}
.owcmodalZIndex {
  position: relative;
  z-index: 10001;
}
.MuiAutocomplete-popper {
  z-index: 10200 !important;
}
