.cardApplicationQuickAccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 184px;
  height: 116px;
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  &:hover {
    border: 1px solid rgb(0, 102, 204);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
}

.headerVertAndFavIcon {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-top: 4px;
}

.contentIcon {
  display: flex;
  justify-content: center;
  width: 150px;
  margin: 0;
  margin-top: -5px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}

.footerApplicationName {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  padding: 0 5px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;
}

.applicationName {
  color: var(--one-color-legacy-gray-800);
}

.IconPreviewStyled > img {
  box-sizing: border-box;
  object-fit: cover;
  display: block;
  width: 25px;
  height: 25px;
}
