.mainBoxForm {
  width: 100%;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 25px;
}

.actionFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.MainPageBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  background: var(--one-color-white);
  height: 539px;
}

.headerTitle {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}

.severityTitle {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;
}
