.dashbStyled {
  width: 100%;
  padding: 0 0 20px 0;
  @media only screen and (max-width: 960px) {
    padding: 0 12px;
  }
}
.SectionName {
  display: flex;
  align-items: flex-end;
  margin-top: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
.DashboardBackground {
  background: var(--one-color-cobas-white);
  margin-top: 20px;
  min-height: 200px;
  border-radius: var(--one-border-radius-4);
}
